import React, { useState } from "react";
import "./LeadsExplorer.css";

const LeadsExplorer = ({ leads, openModal, formatDate, updateLeadStatus }) => {
  // Valor inicial "nuevo" (puedes cambiarlo si prefieres iniciar con "todos")
  const [filter, setFilter] = useState("todos");

  // Filtrado de leads según el estado seleccionado
  const filteredLeads = leads.filter(
    (lead) => filter === "todos" || lead.status === filter
  );

  // Clases de CSS según el estado
  const getStatusClass = (status) => {
    switch (status) {
      case "nuevo":
        return "lead-explorer-status-new";
      case "en proceso":
        return "lead-explorer-status-in-progress";
      case "atendido":
        return "lead-explorer-status-attended";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="lead-explorer-filter-container">
        <label htmlFor="status-filter">Filtrar por estado:</label>
        <select
          id="status-filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="todos">Todos</option>
          <option value="nuevo">Nuevo</option>
          <option value="en proceso">En Proceso</option>
          <option value="atendido">Atendido</option>
        </select>
      </div>

      <table className="lead-explorer-table">
        <thead>
          <tr>
            {["Nombre", "Teléfono", "Correo", "Estado", "Creado el", "Ver más"].map(
              (header) => (
                <th key={header} className="lead-explorer-table-header">
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {filteredLeads.map((lead) => (
            <tr key={lead.id}>
              <td className="lead-explorer-table-cell">{lead.name}</td>
              <td className="lead-explorer-table-cell">{lead.phone}</td>
              <td className="lead-explorer-table-cell">{lead.email}</td>
              <td
                className={`lead-explorer-table-cell ${getStatusClass(lead.status)}`}
              >
                <select
                  value={lead.status}
                  onChange={(e) => updateLeadStatus(lead.id, e.target.value)}
                  className="lead-explorer-status-dropdown"
                >
                  <option value="nuevo">Nuevo</option>
                  <option value="en proceso">En Proceso</option>
                  <option value="atendido">Atendido</option>
                </select>
              </td>
              <td className="lead-explorer-table-cell">
                {formatDate(lead.created_at)}
              </td>
              <td className="lead-explorer-table-cell">
                <button
                  onClick={() => openModal(lead)}
                  className="lead-explorer-button-view-more"
                >
                  Ver más
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default LeadsExplorer;
