import React, { useState, useEffect } from "react";
import LeadsExplorer from "../components/Leads/LeadsExplorer";
import "./Leads.css";

function Leads() {
  // Detectamos si es desarrollo o producción
  const isDev = process.env.NODE_ENV === "development";

  // URLs para consumo y autenticación (ajústalas a tu caso)
  const API_URL = isDev
    ? "http://localhost/consumir_dev.php"
    : "/consumir.php";

  const AUTH_URL = isDev
    ? "http://localhost/auth_dev.php"
    : "/auth.php";

  // Estados
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [error, setError] = useState("");

  // Manejo de formulario de inicio de sesión
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(AUTH_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (data.success) {
        setIsAuthenticated(true);
        setError("");
      } else {
        setError(data.error || "Credenciales inválidas. Inténtalo de nuevo.");
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Hubo un problema al conectarse al servidor.");
    }
  };

  // Cargar leads al autenticarse
  useEffect(() => {
    if (isAuthenticated) {
      fetch(API_URL)
        .then((response) => response.text())
        .then((text) => {
          try {
            return JSON.parse(text);
          } catch (error) {
            throw new Error(`Respuesta inválida del servidor: ${text}`);
          }
        })
        .then((data) => setLeads(data))
        .catch((err) => {
          console.error("Error:", err);
          setError("Error al cargar los leads.");
        });
    }
  }, [isAuthenticated, API_URL]);

  // Actualizar estado de un lead (ejemplo: "nuevo" -> "atendido")
  const updateLeadStatus = (id, newStatus) => {
    fetch(API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id, status: newStatus }),
    })
      .then((response) => response.json())
      .then(() => {
        setLeads((prevLeads) =>
          prevLeads.map((lead) =>
            lead.id === id ? { ...lead, status: newStatus } : lead
          )
        );
      })
      .catch((err) => console.error("Error al actualizar el estado:", err));
  };

  // Mostrar el formulario de login si NO está autenticado
  if (!isAuthenticated) {
    return (
      <div className="leads-page-login-container">
        <form onSubmit={handleLogin} className="leads-page-login-form">
          <h2 className="leads-page-login-title">Iniciar sesión</h2>
          <input
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="leads-page-login-input"
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="leads-page-login-input"
          />
          {error && <p className="leads-page-error">{error}</p>}
          <button type="submit" className="leads-page-login-button">
            Ingresar
          </button>
        </form>
      </div>
    );
  }

  // Si está autenticado, mostramos la lista de leads
  return (
    <div className="leads-page-container">
      <h1 className="leads-page-title">Lista de Leads</h1>

      <LeadsExplorer
        leads={leads}
        markAsAttended={(id) =>
          fetch(API_URL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id }),
          })
            .then((response) => response.json())
            .then(() =>
              setLeads((prevLeads) =>
                prevLeads.map((lead) =>
                  lead.id === id ? { ...lead, status: "atendido" } : lead
                )
              )
            )
            .catch((err) => console.error(err))
        }
        openModal={setSelectedLead}
        formatDate={(timestamp) => {
          if (!timestamp) return "Fecha no disponible";
          const date = new Date(timestamp);
          return isNaN(date.getTime())
            ? "Fecha inválida"
            : `${date.getDate().toString().padStart(2, "0")}/${(
                date.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}/${date.getFullYear()}`;
        }}
        updateLeadStatus={updateLeadStatus}
      />

      {/* Modal de detalles */}
      {selectedLead && (
        <div className="leads-page-modal">
          <div className="leads-page-modal-content">
            <h2>Detalles de {selectedLead.name}</h2>
            {Object.entries(selectedLead).map(([key, value]) => (
              <p key={key}>
                <strong>{key}: </strong>
                {value}
              </p>
            ))}
            <button
              className="leads-page-button"
              onClick={() => setSelectedLead(null)}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Leads;
